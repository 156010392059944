exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-finishes-js": () => import("./../../../src/pages/finishes.js" /* webpackChunkName: "component---src-pages-finishes-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-global-presence-js": () => import("./../../../src/pages/global-presence.js" /* webpackChunkName: "component---src-pages-global-presence-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-luxury-design-js": () => import("./../../../src/pages/luxury-design.js" /* webpackChunkName: "component---src-pages-luxury-design-js" */),
  "component---src-pages-specifications-js": () => import("./../../../src/pages/specifications.js" /* webpackChunkName: "component---src-pages-specifications-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-zero-energy-js": () => import("./../../../src/pages/zero-energy.js" /* webpackChunkName: "component---src-pages-zero-energy-js" */)
}

